@import "variables";
@import "general";
@import "media-queries";

.radio, .checkbox {
    display: inline-block;
}

@include respond-above(md) {
    .element {
      font-weight: bold;
    }
    body
    {
        font: 14px "Lucida Grande", Helvetica, Arial, sans-serif;
        width: 100%;
        height: 100%;
    }

    .overview .form-group
    {
        margin: 0;
        height: 30px;
    }

    ul {
        text-align: left;
    }

    .htmlDiv {
        display: block;
        width: 915px;
        color: #314397;
    }

    .datepickerHint { 
        width: 90% !important;
    }

    .datepickerRangeHint { 
        width: 95% !important;
        display: inline-block;
    }	

    .controls.labelDiv {
        width: 25%;
    }

    .backgroundDiv
    {
        width: 100%; 
        height: 100%; 
        position: fixed; 
        left: 0px; 
        top: 0px; 
        z-index: -1;
    }

    .repButton
    {
        height: 40px;
        margin-top: auto;
        margin-bottom: auto;
        font: 14px "Lucida Grande", Helvetica, Arial, sans-serif;
        font-weight: bold;
        color: #314397;
    }

    .stretch
    {
        width:100%;
        height:100%;
    }

    div.form-group-span
    {
        display: block;
    }

    .header
    {
        position: relative;
        left: -7px;
        background-size: 100%; 
        width: 975px !important;
        background-repeat: no-repeat;
        background-image:url(/images/bg_head.png);
    }

    .reFsetCancelDiv {
        float: right;
    }

    .repFsetCancelText {
        display: inline-block;
        padding-right: 5px;
    }

    .repFsetCancel 
    {
        width: 35px;
        height: 35px;
        cursor: pointer;
    }

    .travel
    {
        background-image:url(/images/bg_header_travel.png);
    }

    .medical
    {
        background-image:url(/images/bg_header_medical.png);
    }

    .lPageDiv
    {
        margin-top: 50px;
    }

    .container
    {
        width: 100%;
    }

    a
    {
        color: #00b7ff;
    }

    .accDel
    {
        position: relative;
        top: 0px;
        font-size: 25px;
    }

    .hidden
    {
        visibility: hidden;
    }

    div.ui-datepicker
    {
        font-size:10px;
    }

    .helpIcon, .ui-datepicker-trigger
    {
        position: relative;
        top: -2px;
        width: 25px;
        height: 25px;
    }

    .accInput
    {
        width: 32% !important;
        border: 2px solid #c1e0f2 !important;
        text-align: center;
    }

    .help-inline
    {
        padding-top: 7px;
    }

    .linkDiv
    {
        text-align: center;
        display: table;
        float: right;
        width: 400px;
        margin-top: 10px;
        margin-bottom: 50px;
        padding-right: 20px;
    }

    .linkRowDiv
    {
        display: table-row;
    }

    .tableHasErr .inputWrapper .accInput
    {
        width: 31% !important;
    }
    .tableHasErr .inputWrapper input
    {
        width: 90% !important;
    }

    .tableHasErr .inputDiv
    {
        width: 40% !important;
    }

    .logoRow
    {
        position: relative;
        top: 20px;
        width: 960px;
        height: 96px;
        display: table;
        margin-bottom: 45px;
    }

    .logoRow div
    {
        display: table-cell;
        width: 1%;
        vertical-align:middle;
        text-align:center;
        width: 220px;
    }

    .phone, .write, .contract, .logout
    {
        display: inline-block;
        width: 25px;
        height: 25px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .write
    {
        background-image: url(/images/icons/write_off.png);
    }

    .write:hover
    {
        background-image: url(/images/icons/write_on.png);
    }

    .contract
    {
        background-image: url(/images/icons/contract_off.png);
    }

    .contract:hover
    {
        background-image: url(/images/icons/contract_on.png);
    }

    .phone
    {
        background-image: url(/images/icons/phone_off.png);
    }

    .logout
    {
        background-image: url(/images/icons/logout_off.png);
    }

    .logout:hover
    {
        background-image: url(/images/icons/logout_on.png);
    }


    .linkDesc
    {
        display:inline-block; 
        height: 32px; 
        vertical-align:middle;
        color: #7e8885;
    }

    .linkDesc:hover
    {
        color: #314397;
    }

    .reqSpan
    {
        font-size: 25px;
        color: #30c4fb;	
    }

    .phone:hover
    {
        background-image: url(/images/icons/phone_on.png);
    }

    .rightHelpButton 
    {
        margin-bottom: auto;
        margin-top: auto;
        display: block;
    }

    .headText h1
    {
        width: 500px !important;
        font-family: Arial;
        font-weight: bold;
        font-size: 30px;
        color: #314397;
    }

    .linkDiv div
    {
        padding: 0 0 0 0;
    }

    select.form-control 
    {
        height: 38px;
    }

    .ui-datepicker-trigger
    {
        margin-left: 0px;
    }

    .linkDiv div
    {
    }


    .linkDiv div p
    {
        font-size: 80%;
    }

    .progressRow
    {
        height: 60px;
    }

    #progress
    {
        margin: 0 auto;
        width: 95%;	
        background-size: 100% 100%; 
        background-repeat: no-repeat;
        background-image: url(/images/progressbar.png);
        border: 0;
    }

    .ui-progressbar
    {
        position: relative;
    }

    #upper-label
    {
        font-weight: bold;
        text-shadow: 1px 1px 0 #fff;
        padding-bottom: 2px;
        padding-top: 25px;
    }

    .ui-progressbar-value
    {
        height: 110% !important;
    }
    .progress-label 
    {
        position: absolute;
        left: 49%;
        padding-top: 0px;
        font-weight: bold;
        color: #314397;
        height: 31px;
        width: 75px;
        text-align: center;
        text-shadow: 1px 1px 0 #fff;
        background-size: 100% 100%; 
        background-repeat: no-repeat;
        font-size: 24px;
        background-image: url(/images/progresslabelbg.png);
    }

    .progress-pointer
    {
        position: relative;
        left: 49%;
        height: 50px;
        width: 52px;
        top: -47px;
        background-repeat: no-repeat;
        background-image: url(/images/progresspointer.png);
    }

    .pageContainer
    {
        width: 100%;
        text-align: center;
    }

    .pageDiv
    {
        display: block;
        max-width: 960px;
        min-width: 960px;
        min-height: 500px;
        margin: 0 auto;
        background-image: url(/images/bg_inner.png);
        background-size: 100% 100%; 
        background-repeat: no-repeat;
    }

    .ui-datepicker-month, .ui-datepicker-year
    {
        color: #1C94C4 !important;
    }

    .inputIconDiv, .inputCancelDiv
    {
        display: inline-block;
        width: 25px;
        height: 25px;
        vertical-align: top;
    }


    .inputElement
    {
        position: relative;
        top: -4px;
        border: 0px;
        padding: 0px;
        height: 100%;
        width: calc(100% - 25px);
        background-color: #d4effb;
    }


    .recapFormGroup
    {
        display: table-row;
    }

    input[type=file].inputElement 
    {
        top: 0px;
    }

    p
    {
        margin: 0 0 3px;
    }

    .logoDiv
    {
        text-align: center;
    }

    .links
    {
        font: 17px "Lucida Grande", "Helvetica", "Arial", fantasy;
        font-weight: bold;    
        float: right;
    }

    .logo
    {
        vertical-align: middle;
        max-width: 230px;
        max-height: 85px;
    }

    .elogo
    {
    }

    .img-ver-helper
    {
        display: inline-block;
        vertical-align: middle;	
    }

    .header
    {
        margin-bottom: 0px;
    }

    .header .row
    {
        display: table;
        width: 100%;
    }

    .header .row [class*="col-"]
    {
        float: none;
        display: table-cell;
        vertical-align: middle;
    }

    .overview .form-group
    {
        margin: 0;
        height: 30px;
    }

    .form-group
    {
        display: table-row;
        width: 100%;
        padding:0;
        margin-bottom: 5px;
        height: 45px;
    }

    .controls 
    {
        padding: 0;
        margin: 0;
    }

    .reqAnnon, .sendHint, .overviewRemind
    {
        color: #314397;
    }

    .labelDiv, .inputDiv, .helpDiv, .emailNot
    {
        display: table-cell;
        color: #314397;
    }

    .emailNot
    {
        text-align: center;
        vertical-align: middle;
        height: 100%;
    }

    .labelDiv
    {
        font-size: 15px;
    }

    .inputDiv
    {
        width: 78%;	
        vertical-align: middle;
    }

    .dateRangeWrapper 
    {
        margin-top: 5px;
    }

    .dateRangeDiv
    {
        height: 100%;
    }

    .tableHasErr .inputWrapper .dateRangeInput
    {
        width: 20% !important;
    }

    .tableHasErr .inputWrapper .dateRangeLabel
    {
        width: 15%;
    }

    .dateRangeDiv > img.ui-datepicker-trigger
    {
        margin-bottom: 5px;
    }

    .dateRangeLabel
    {
        height: 100%;
        margin: 0 0 0 0;
        margin-right: 10px;
        width: 7%;
    }

    .dateRangeInput
    {
        width: 35%;
        height: 100%;
        padding-top: 0px;
    }

    .inputWrapper input:focus, .inputWrapper select:focus
    {
        border: 0;
        outline: 0;
    }
    
    .labelDiv > .control-label 
    {
      text-align: center;
      max-width: 200px;
      vertical-align: middle;
    }

    .textADiv
    {
        height: 120px;
        border-top: 5px solid transparent;
        padding-bottom: 5px;
    }

    .textAWrapper
    {
        height: 100% !important;
    }

    .multiFileInput {
        width: calc(100% - 50px);
    }

    .multiFileInput.noHint {
        width: calc(100% - 25px);
    }

    .multiFileCancel {
        cursor: pointer; /* hand-shaped cursor */
        cursor: hand; /* for IE 5.x */
    }

    .noHint
    {
        width: 100%;
    }

    .inputWrapper
    {
        background-color: #d4effb;
        height: 39px;
        padding: 5px;
        padding-left: 10px;
        border-top: 2px solid #c1e0f2; 
        margin-bottom: 3px;
        margin-top: 3px;
    }

    .checkBoxWrapper
    {
        background-color: #d4effb;
        height: 100%;
        padding: 5px;
        padding-left: 10px;
        border-top: 2px solid #c1e0f2; 
    }

    .checkBoxWrapper
    {
        background-color: transparent;
        border-top: 0; 
    }

    .form-control[type=file]
    {
        display: inline !important;
    }	

    .overview fieldset
    {
        margin-bottom: 25px;
    }

    .maindiv
    {
        padding: 0;
    }

    .formdiv
    {
        padding: 0;
        padding-top: 25px;
        padding-bottom: 25px;
        text-align: center;
    }

    .buttonDiv
    {
        display: table;
        margin-top: 40px;
        width: 100%;
    }


    .buttonRow
    {
        display: table-row;
        width: 100%;
    }

    .leftDiv, .rightDiv, .centerDiv
    {
        display: table-cell;
    }

    .centerDiv
    {
        width: 600px;
        vertical-align: middle;
    }

    .leftDiv
    {
        padding-left: 20px;
    }

    .leftButton
    {
        width: 62px;
        height: 62px;
        padding: 62px 0 0;
        margin: 0;
        border: 0;
        background: transparent url(/images/left.png) no-repeat center top;
        overflow: hidden;
        cursor: pointer; /* hand-shaped cursor */
        cursor: hand; /* for IE 5.x */
    }

    .leftButton:focus, .rightButton:focus
    {
        outline: 0;
    }

    .linksSubDiv
    {
        display: table-cell;
        width: 135px;
    }

    .rightButton
    {
        width: 62px;
        height: 62px;
        padding: 62px 0 0;
        margin: 0;
        border: 0;
        background: transparent url(/images/right.png) no-repeat center top;
        overflow: hidden;
        cursor: pointer; /* hand-shaped cursor */
        cursor: hand; /* for IE 5.x */
    }

    .finalButton
    {
        width: 62px;
        height: 62px;
        cursor: pointer; /* hand-shaped cursor */
        cursor: hand; /* for IE 5.x */
    }

    .rightDiv
    {
        padding-right: 20px;
    }
    
    .sendHint
    {
      font-weight: bold;
    }

    
    .my-navig {
        display: table;

        ul  {
            display:table-row;
            width:100%;
            border-collapse:collapse;
            margin:0 0 20px;
            padding:0;
            list-style:none;

            li {
                display:table-cell;
                width:1%;
                vertical-align:middle;
                text-align:center;

                a {
                    display:block;
                    color: white;
                }
            }
        }
    

        #navigation {
            li {
                display:table-cell;
                width:1%;
                vertical-align:middle;
                text-align:center;
                height: 45px;
                border-left: 1px solid #ffffff;
                border-right: 1px solid #ffffff;
                background-color: #304396;
            }

            li.active
            {
                background-color: #e53138;
                a {
                    position: relative;
                    top: -10px;
                    width: 205px;
                    margin: 0 auto;
                    font-size: 18px;
                }
            }
        }

        #upper {
            li {
                height: 15px;
                border-radius: 10px 10px 0 0;
                border-left: 1px solid #ffffff;
                border-right: 1px solid #ffffff;
            }
            
            li.active {
                background-color: #e53138;
            }
        }
      }



    .has-error span
    { 
        color: #A94442;
    }

    .required
    {
        border-width: 3px;
    }

    .has-success
    {
        outline-color: #2B542C !important;
    }

    .has-error
    {
        outline-color: #A94442 !important;
    }

    .form-group
    {
        outline-color: #CCCCCC;
    }

    .controls
    {
        outline-color: inherit;
    }

    .checkbox
    {
        outline-color: inherit;
    }

    .overviewdiv
    {
        display: table-cell;
        padding-top: 7px;
        text-align: left;
        width: 75%;
        word-wrap: break-word;
    }

    fieldset
    {
        padding-left: 15px;
        padding-right: 15px;
        border: 0;
        width: 100%;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .fieldSetDiv
    {
        width: 100%;
        display: table;
        table-layout: fixed;
    }

    legend
    {
        border: 0;	
        font-size: 30px;
        color: #939b99;
        text-align: left;
    }

    input[type=file] 
    {
        display: inline-block;
    }

    .right
    {
        float: 	right;
    }

    .nav
    {
    }
}

.rightButtonTableDiv
{
  width: 100%;
  height: 100%;
}

.rightButtonTable
{
  margin-left: auto;
  margin-right: 0;
}

.checkboxspan
{
	padding-top: 5px;
	text-align: left;
}

.rotateAnimation {
	animation: rotation 2s infinite linear;
	-webkit-animation: rotation 2s infinite linear;
	-ms-animation: rotation 2s infinite linear;
}

@keyframes rotation {
	0% { 
		transform: rotate(0deg);
	}
    100% { 
		transform: rotate(360deg); 
	}
}

@-webkit-keyframes rotation {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
	}
}

@-ms-keyframes rotation {
	from {
		-ms-transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(359deg);
	}
}